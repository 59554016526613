import React from 'react';

import { Title, UnderlinedHeadline } from '../components/global';
import { ContactForm } from '../components/form';

const classes = {
  list: 'list-inside list-disc leading-4',
  link: 'text-royal-blue hover:text-black'
};

const KontaktPage = ({ location }) => (
  <>
    <Title prefix="Kontaktformular" />
    <section className="bg-champagne-blue">
      <UnderlinedHeadline as="h1" containerClasses="text-center px-4">
        Event anfragen
      </UnderlinedHeadline>
    </section>
    <section>
      <div className="grid items-start gap-12 max-w-[1056px] px-4 mx-auto md:grid-cols-3">
        <ContactForm state={location.state} />
        <aside className="space-y-8 md:sticky md:top-24 md:order-first">
          <UnderlinedHeadline as="h4">Informationen</UnderlinedHeadline>
          <ul className={classes.list}>
            <li>
              <a
                href="/2023_CodeCampN_Preisliste_Raumbuchungen.pdf"
                className={classes.link}
              >
                Preisliste
              </a>
            </li>
          </ul>
        </aside>
      </div>
    </section>
  </>
);

export default KontaktPage;
